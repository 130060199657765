import { FunctionComponent, useState, useEffect } from "react";

import logo from "./img/cppLogo.png";

import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import Select, { components, createFilter } from "react-select";

const options = [
  {
    value: "state_alabama",
    label: "Alabama",
    state_code: "AL",
  },
  {
    value: "state_alaska",
    label: "Alaska",
    state_code: "AK",
  },
  {
    value: "state_arizona",
    label: "Arizona",
    state_code: "AZ",
  },
  {
    value: "state_arkansas",
    label: "Arkansas",
    state_code: "AR",
  },
  {
    value: "state_california",
    label: "California",
    state_code: "CA",
  },
  {
    value: "state_colorado",
    label: "Colorado",
    state_code: "CO",
  },
  {
    value: "state_connecticut",
    label: "Connecticut",
    state_code: "CT",
  },
  {
    value: "state_delaware",
    label: "Delaware",
    state_code: "DE",
  },
  {
    value: "state_florida",
    label: "Florida",
    state_code: "FL",
  },
  {
    value: "state_georgia",
    label: "Georgia",
    state_code: "GA",
  },
  {
    value: "state_hawaii",
    label: "Hawaii",
    state_code: "HI",
  },
  {
    value: "state_idaho",
    label: "Idaho",
    state_code: "ID",
  },
  {
    value: "state_illinois",
    label: "Illinois",
    state_code: "IL",
  },
  {
    value: "state_indiana",
    label: "Indiana",
    state_code: "IN",
  },
  {
    value: "state_iowa",
    label: "Iowa",
    state_code: "IA",
  },
  {
    value: "state_kansas",
    label: "Kansas",
    state_code: "KS",
  },
  {
    value: "state_kentucky",
    label: "Kentucky",
    state_code: "KY",
  },
  {
    value: "state_louisiana",
    label: "Louisiana",
    state_code: "LA",
  },
  {
    value: "state_maine",
    label: "Maine",
    state_code: "ME",
  },
  {
    value: "state_maryland",
    label: "Maryland",
    state_code: "MD",
  },
  {
    value: "state_massachusetts",
    label: "Massachusetts",
    state_code: "MA",
  },
  {
    value: "state_michigan",
    label: "Michigan",
    state_code: "MI",
  },
  {
    value: "state_minnesota",
    label: "Minnesota",
    state_code: "MN",
  },
  {
    value: "state_mississippi",
    label: "Mississippi",
    state_code: "MS",
  },
  {
    value: "state_missouri",
    label: "Missouri",
    state_code: "MO",
  },
  {
    value: "state_montana",
    label: "Montana",
    state_code: "MT",
  },
  {
    value: "state_nebraska",
    label: "Nebraska",
    state_code: "NE",
  },
  {
    value: "state_nevada",
    label: "Nevada",
    state_code: "NV",
  },
  {
    value: "state_new_hampshire",
    label: "New Hampshire",
    state_code: "NH",
  },
  {
    value: "state_new_jersey",
    label: "New Jersey",
    state_code: "NJ",
  },
  {
    value: "state_new_mexico",
    label: "New Mexico",
    state_code: "NM",
  },
  {
    value: "state_new_york",
    label: "New York",
    state_code: "NY",
  },
  {
    value: "state_north_carolina",
    label: "North Carolina",
    state_code: "NC",
  },
  {
    value: "state_north_dakota",
    label: "North Dakota",
    state_code: "ND",
  },
  {
    value: "state_ohio",
    label: "Ohio",
    state_code: "OH",
  },
  {
    value: "state_oklahoma",
    label: "Oklahoma",
    state_code: "OK",
  },
  {
    value: "state_oregon",
    label: "Oregon",
    state_code: "OR",
  },
  {
    value: "state_pennsylvania",
    label: "Pennsylvania",
    state_code: "PA",
  },
  {
    value: "state_rhode_island",
    label: "Rhode Island",
    state_code: "RI",
  },
  {
    value: "state_south_carolina",
    label: "South Carolina",
    state_code: "SC",
  },
  {
    value: "state_south_dakota",
    label: "South Dakota",
    state_code: "SD",
  },
  {
    value: "state_tennessee",
    label: "Tennessee",
    state_code: "TN",
  },
  {
    value: "state_texas",
    label: "Texas",
    state_code: "TX",
  },
  {
    value: "state_utah",
    label: "Utah",
    state_code: "UT",
  },
  {
    value: "state_vermont",
    label: "Vermont",
    state_code: "VT",
  },
  {
    value: "state_virginia",
    label: "Virginia",
    state_code: "VA",
  },
  {
    value: "state_washington",
    label: "Washington",
    state_code: "WA",
  },
  {
    value: "state_west_virginia",
    label: "West Virginia",
    state_code: "WV",
  },
  {
    value: "state_wisconsin",
    label: "Wisconsin",
    state_code: "WI",
  },
  {
    value: "state_wyoming",
    label: "Wyoming",
    state_code: "WY",
  },
];

const customStyles = {
  container: (base, state) => ({
    ...base,
    width: "100%",
    // maxWidth: "380px",
    backgroundColor: "white",
    borderRadius: 50,
    paddingLeft: 4,
    boxShadow: "4px 4px 15px 0px #d1d5db",
    margin: "0 auto",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "white",
    width: state.isFocused ? "100%" : "287px",
    color: state.isFocused ? "#4a4a4a" : "#929292",
    boxShadow: "none",
    border: state.isFocused ? 0 : 0,
    padding: "4px 7px",
    borderRadius: "50px",
    fontSize: 18,
    transition: "0.2s",
    webkitTransition: "0.2s",
    // width: "287px",
    width: "100%",
    "&:hover": {
      cursor: "pointer",
      opacity: "0.9",
      width: "100%",
      color: "#4a4a4a",
    },
  }),
  placeholder: (base, state) => ({
    ...base,
    color: state.isFocused ? "#929292" : "#929292",
    fontWeight: "400",
    "&:hover": {
      // color: "#4a4a4a"
    },
  }),
  menu: (base, state) => ({
    ...base,
    borderRadius: "4px",
    border: "none",
    boxShadow:
      "5px 7px 14px 3px rgba(228, 228, 234, 0.57), 1px 3px 7px 3px rgba(228, 228, 234, 0.57)",
    // backgroundColor: "white",
    //
  }),
  menuList: (base, state) => ({
    ...base,
    borderRadius: "7px",
    border: "none",
    boxShadow:
      "5px 7px 14px 3px rgba(228, 228, 234, 0.57), 1px 3px 7px 3px rgba(228, 228, 234, 0.57)",
  }),

  option: (provided, state) => ({
    ...provided,
    // backgroundColor: state.isFocused ? "#5762c21b" : "White",
    color: state.isFocused ? "black" : "#4a4a4a",
    backgroundColor: state.isFocused ? "#f9fafb" : "white",
    fontWeight: state.isFocused ? 600 : 500,
    borderBottom: "solid 1px #ebeef6",
    padding: 7,
    fontSize: 18,
    borderRadius: "3px",
    "&:hover": {
      backgroundColor: "#f9fafb",
      color: "black",
      fontWeight: 600,
    },
  }),
  valueContainer: (base, state) => ({
    ...base,
    paddingLeft: 33,
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    display: "none",
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    display: "none",
  }),
};

const ValueContainer = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && (
          <i
            className="fa fa-search"
            aria-hidden="true"
            style={{ position: "absolute", left: 8 }}
          />
        )}
        {children}
      </components.ValueContainer>
    )
  );
};

function Home() {
  const [selectedState, setSelectedState] = useState("");
  const [drugSearch, setDrugSearch] = useState("");
  const [pharmacies, setPharmacies] = useState([]);
  const [prices, setPrices] = useState([]);
  const [units, setUnits] = useState(30);

  const [selectedPharmacies, setSelectedPharmacies] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlState = urlParams.get("state");

    urlState !== null && setSelectedState(urlState);

    axios
      .get(`https://rxdeals-api-bgzwqgpyxq-uc.a.run.app/pharmacies`)
      .then((res) => {
        const data = res.data;
        setPharmacies(data);
        // console.log(data);
      });
  }, []);

  useEffect(() => {
    if (selectedPharmacies.length > 0) {
      getPrices();
    }
  }, [selectedPharmacies]);

  const getPrices = () => {
    axios
      .get(
        `https://rxdeals-api-bgzwqgpyxq-uc.a.run.app/prices/${selectedPharmacies[0].npi}`
      )
      .then((res) => {
        const data = res.data;
        setPrices(data);
        // console.log(data);
      });
  };

  useEffect(() => {
    if (selectedPharmacies.length > 0) {
      document.getElementById("row-1").style.height = 0;
      document.getElementById("row-1").style.opacity = 0;
    }
    // console.log(selectedPharmacies);
    // console.log(pharmacies);
  }, [selectedPharmacies]);

  return (
    <div className="container-fluid p-2 homePageContainer">
      <div className="row no-gutters">
        <div className="col-md-12 text-center pt-5 pb-5">
          <img
            onClick={() => {
              window.location.href = "https://costpluspharmacies.com";
            }}
            src={logo}
            alt="Cost-Plus Pharmacies logo"
            className="mainLogo"
          />
        </div>
      </div>
      <div className="row no-gutters">
        <div className="col-md-5 m-auto">
          <div className="searchContainer">
            <Select
              options={options}
              styles={customStyles}
              components={{ ValueContainer }}
              onChange={(e) => {
                setSelectedState(e.value);
                navigate(`?state=${e.value}`);
              }}
              inputId="selector"
              placeholder="Select Your State"
            />
          </div>
        </div>
      </div>
      <div className="row no-gutters pt-4 pb-5">
        <div className="col-md-6 m-auto pharmacy-row-container">
          {selectedState !== "" && (
            <div
              className="label-row"
              style={{
                display:
                  pharmacies.filter(
                    (p) =>
                      p.state ===
                        options.filter((o) => o.value === selectedState)[0]
                          .state_code && p[selectedState] === "Yes"
                  ).length > 0
                    ? "block"
                    : "none",
              }}
            >
              Located in{" "}
              {`${selectedState
                .split("_")[1]
                .slice(0, 1)
                .toUpperCase()}${selectedState.split("_")[1].slice(1)}`}{" "}
              (Pick-up, mail, or delivery available)
            </div>
          )}
          {pharmacies.map((p, i) => {
            // First display pharmacies located within the state itself
            if (
              p.state !==
              options.filter((o) => o.value === selectedState)[0]?.state_code
            ) {
              return null;
            }
            let logoSrc = "";
            if (p["pharmacy_logo"].includes("?id=")) {
              logoSrc = `https://drive.google.com/thumbnail?id=${
                p["pharmacy_logo"].split("?id=")[1]
              }&sz=w200`;
            }

            if (p["pharmacy_logo"].includes("/file/d/")) {
              logoSrc = `https://drive.google.com/thumbnail?id=${
                p["pharmacy_logo"].split("/file/d/")[1].split("/view?")[0]
              }&sz=w200`;
            }

            if (p.pharmacy_npi === null) {
              return (
                <a
                  href={p.pharmacy_website}
                  target="_blank"
                  className={`pharmacy-row ${i == 0 && "top"}`}
                  key={`key-${p.pharmacy_website}-${p.pharmacy_name}`}
                  style={{
                    display: p[selectedState] === "Yes" ? "flex" : "none",
                  }}
                >
                  <div className="home-logo-box">
                    <img className="home-logo" src={logoSrc} alt="" />
                  </div>
                  <div className="home-info-box">
                    <h2 className="mb-0">{p.pharmacy_name}</h2>
                    <p className="mb-0">{`${p?.pharmacy_address_line_1}, ${p?.city} ${p?.state} ${p?.zip}`}</p>
                    <p className="mb-0 mt-0">
                      <i className="fa fa-mobile"></i> {p.pharmacy_phone}
                    </p>
                  </div>
                </a>
              );
            }

            return (
              <Link
                to={p.pharmacy_npi}
                className={`pharmacy-row ${i == 0 && "top"}`}
                key={`key-${p.pharmacy_npi}-${p.pharmacy_name}`}
                style={{
                  display: p[selectedState] === "Yes" ? "flex" : "none",
                }}
              >
                <div className="home-logo-box">
                  <img className="home-logo" src={logoSrc} alt="" />
                </div>
                <div className="home-info-box">
                  <h2 className="mb-0">{p.pharmacy_name}</h2>
                  <p className="mb-0">{`${p?.pharmacy_address_line_1}, ${p?.city} ${p?.state} ${p?.zip}`}</p>
                  <p className="mb-0 mt-0">
                    <i className="fa fa-mobile"></i> {p.pharmacy_phone}
                  </p>
                </div>
              </Link>
            );
          })}
          {selectedState !== "" && (
            <div
              className="label-row"
              style={{
                display:
                  pharmacies.filter(
                    (p) =>
                      p.state !==
                        options.filter((o) => o.value === selectedState)[0]
                          .state_code && p[selectedState] === "Yes"
                  ).length > 0
                    ? "block"
                    : "none",
              }}
            >
              Licensed for mail-only to{" "}
              {`${selectedState
                ?.split("_")[1]
                .slice(0, 1)
                .toUpperCase()}${selectedState.split("_")[1].slice(1)} ${
                selectedState?.split("_").length > 2
                  ? `${selectedState
                      ?.split("_")[2]
                      .slice(0, 1)
                      .toUpperCase()}${selectedState.split("_")[2].slice(1)}`
                  : ``
              }`}
            </div>
          )}
          {pharmacies.map((p, i) => {
            // Next display pharmacies that can operate in the state but aren't located in the state
            if (
              p.state ===
              options.filter((o) => o.value === selectedState)[0]?.state_code
            ) {
              return null;
            }
            let logoSrc = "";
            if (p["pharmacy_logo"].includes("?id=")) {
              logoSrc = `https://drive.google.com/thumbnail?id=${
                p["pharmacy_logo"].split("?id=")[1]
              }`;
            }

            if (p["pharmacy_logo"].includes("/file/d/")) {
              logoSrc = `https://drive.google.com/thumbnail?id=${
                p["pharmacy_logo"].split("/file/d/")[1].split("/view?")[0]
              }`;
            }
            return (
              <Link
                to={p.pharmacy_npi}
                className={`pharmacy-row ${i == 0 && "top"}`}
                key={`key-${p.pharmacy_npi}-${p.pharmacy_name}`}
                style={{
                  display: p[selectedState] === "Yes" ? "flex" : "none",
                }}
              >
                <div className="home-logo-box">
                  <img className="home-logo" src={logoSrc} alt="" />
                </div>
                <div className="home-info-box">
                  <h2 className="mb-0">{p.pharmacy_name}</h2>
                  <p className="mb-0">{`${p?.pharmacy_address_line_1}, ${p?.city} ${p?.state} ${p?.zip}`}</p>
                  <p className="mb-0 mt-0">
                    <i className="fa fa-mobile"></i> {p.pharmacy_phone}
                  </p>
                </div>
              </Link>
            );
          })}
        </div>
        <div className="col-md-12 m-auto"></div>
        <div className="col-md-5 m-auto">
          {selectedState.length > 0 &&
          pharmacies.filter((ph) => ph[selectedState] && ph).length === 0 ? (
            <div>
              <iframe
                src={`https://tally.so/r/3NqQ4W?state=${selectedState}`}
                frameBorder="0"
                className="notify-form-frame"
                scrolling="no"
              ></iframe>
            </div>
          ) : null}
        </div>
        <div className="col-md-12 m-auto"></div>
        <div className="col-md-5 m-auto text-center pt-4">
          <img
            src={
              "https://firebasestorage.googleapis.com/v0/b/cost-plus-checker.appspot.com/o/images%2Fmap1032024.png?alt=media&token=be815ac2-48c7-44de-b361-3a98660ab035"
            }
            className="map-image"
            alt=""
            style={{
              display: selectedState.length > 0 ? "none" : "block",
            }}
          />
        </div>
        <div className="col-md-12 m-auto"></div>
        <div className="col-md-12 m-auto pt-5 text-center">
          <p>
            Are you a pharmacy owner who would like your store to be listed?
          </p>
          <a href="https://form.jotform.com/230235321797051">
            <button className="button btn btn-primary">Sign up here!</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
